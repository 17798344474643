<template>
  <transition name="menu">
    <form-position :is-edit="true"/>
  </transition>
</template>

<script>
import formPosition from './components/formPosition'

export default {
  name: 'EditformPosition',
  components: { formPosition }
}
</script>

